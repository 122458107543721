<template>
    <b-modal v-model="showModal" @change="onChange()">
        <!--<draggable :list="seriesConfArray" @start="drag=true" @end="drag=false" @change="onMove">
        <div v-for="element in seriesConfArray" :key="element.storageId">{{storageNames.find( x => x.value==element.storageId).text}}</div>
    </draggable>-->
        <draggable tag="ul" :list="seriesConfArray" class="list-group" handle=".handle" @change="onMove">
            <li class="list-group-item center"
                v-for="element in seriesConfArray"
                :key="element.storageId">
                <b-icon-list class="handle"></b-icon-list>
                <span class="text">{{ storageNames.find( x => x.value==element.storageId).text }} </span>
            </li>
        </draggable>
    </b-modal>
</template>

<script>
    import draggable from 'vuedraggable'
    import { BIconList } from 'bootstrap-vue'
    export default {
        name: 'sortChartModal',
        props: ['showSortModal', 'storageNames', 'seriesConfArray'],
        data() {
            return {
                drag:false
            }
        },
        computed: {
            showModal: {
                get: function () {
                    return this.showSortModal
                },
                set: function (newValue) { return newValue }
            },
            //confArray: {
            //    get: function () {
            //        return this.seriesConfArray
            //    },
            //    set: function (newValue) { return newValue }
            //}
        },
        methods: {
            onChange() {
                this.$emit('update:showSortModal', false)
                //this.$emit('update:seriesConfArray', false)
            },
            onMove(value) {
                this.$emit('reOrdered', {oldIndex: value.moved.oldIndex, newIndex: value.moved.newIndex})
            }
        },
        components: {
            draggable,
            BIconList
        }
    }
</script>

<style scoped>
    .handle {
        float: left;
        text-align: center;
    }

    .center {
        display: flex;
        //justify-content: center;
        align-items: center;
    }

    .text {
        margin-left: 20px;
    }
</style>